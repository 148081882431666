import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/imodium-behandelen-diarree/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/medicaments-imodium/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="ee0abaa3a61a5b7897e468236f8ec24d3384fd94"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li className="active" id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview">
                <a href="/fr/medicaments-imodium/"> Médicaments IMODIUM®</a>
              </li>
              <li>
                <a href="/fr/medicaments-imodium/imodium-instant/">
                  IMODIUM® Instant
                </a>
              </li>
              <li>
                <a href="/fr/medicaments-imodium/imodium-duo/">IMODIUM® Duo</a>
              </li>
              <li>
                <a href="/fr/medicaments-imodium/imodium-capsules/">
                  IMODIUM® Capsules
                </a>
              </li>
              
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Conseils en cas de diarrhée</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Conseils en cas de diarrhée"
                  />
                </div>
                <p>
                  Quand il s'agit du traitement des symptômes de la diarrhée, il
                  existe encore de nombreuses incertitudes. Lire quelques
                  conseils.
                </p>
                <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                  Conseils diarrhée
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-5">
            <h1>IMODIUM® pour le traitement des symptômes de la diarrhée</h1>
            <div className="intro row">
              <div className="three columns">
                <h2>Quel IMODIUM® est le plus adapté pour vous?</h2>
              </div>
              <div className="nine columns">
                <p>
                  Il-y-a différents formes de IMODIUM® disponibles pour le
                  traitement symptomatique de la diarrhée. Voici un aperçu des
                  différents médicaments de la gamme&nbsp;
                  <strong>IMODIUM®</strong> qui vous permettra d'avoir une
                  meilleure image de la forme le mieux adaptée à votre cas.
                  {/*Faites aussi le test IMODIUM®*/}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <img
                  alt="IMODIUM®  Instant: Une aide immédiate en cas de diarrhée"
                  src="/assets/files/products/images/Imodium_Instant_2mg_20_tabs_3D_Frontal_FR.png"
                  style={{
                    width: "215px",
                    height: "119px"
                  }}
                />
              </div>
              <div className="eight columns">
                <h4>
                  <em>
                    IMODIUM<sup>®</sup>&nbsp;Instant&nbsp;
                  </em>
                  <em>comprimés orodispersibles</em>
                </h4>
                <p>
                  <em>
                    <strong>
                      IMODIUM<sup>®</sup> Instant:&nbsp;
                    </strong>
                  </em>
                  <em>
                    <strong>Une aide&nbsp;</strong>
                  </em>
                  <em>
                    <strong>pratique</strong>
                  </em>
                  <em>
                    <strong>&nbsp;en cas de diarrhée</strong>
                  </em>
                </p>
                <ul>
                  <li>Fond sur la langue en quelques secondes</li>
                  <li>Facile à avaler et à prendre sans eau.</li>
                  <li>Cela évite qu'une gorgée d'eau puisse déclencher des vomissements chez un patient qui est également nauséeux.</li>
                  <li>Agit rapidement et efficacement</li>
                </ul>
                <p className="text-right">
                  <a href="/fr/medicaments-imodium/imodium-instant/">
                    Plus d'informations sur &nbsp;IMODIUM® Instant
                  </a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <img
                  alt="IMODIUM®  Duo  Aide rapide en cas de diarrhée"
                  src="/assets/files/products/images/ImodiumDuo_18capl_3D_Frontal_FR.PNG"
                  style={{
                    width: "215px",
                    height: "165px"
                  }}
                />
              </div>
              <div className="eight columns">
                <h4>
                  <em>
                    IMODIUM<sup>®</sup>&nbsp;Duo
                  </em>
                </h4>
                <p>
                  <em>
                    <strong>IMODIUM® Duo&nbsp;</strong>
                  </em>
                  <em>
                    <strong>en cas de diarrhée&nbsp;</strong>
                  </em>
                  <em>
                    <strong>accompagnée de crampes&nbsp;</strong>
                  </em>
                  <em>
                    <strong>douloureuses</strong>
                  </em>
                </p>
                <ul>
                  <li>
                    Soulage la diarrhée accompagnée de crampes douloureuses et
                    de ballonnements
                  </li>
                  <li>Association de lopéramide et simeticone</li>
                  {/*<li>Agit rapidement&nbsp; et efficacement</li>*/}
                </ul>
                <p className="text-right">
                  <a href="/fr/medicaments-imodium/imodium-duo/">
                    Plus d'informations sur&nbsp;IMODIUM® Duo
                  </a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <img
                  alt="IMODIUM®  Duo  Aide rapide en cas de diarrhée"
                  src="/assets/files/products/images/Imodium_2mg_20_caps_3D_Frontal_FR.png"
                  style={{
                    width: "215px",
                    height: "161px"
                  }}
                />
              </div>
              <div className="eight columns">
                <h4>
                  <em>IMODIUM</em>
                  <em>
                    <sup>®</sup> Capsules
                  </em>
                </h4>
                <p>
                  <em>
                    <strong>
                      IMODIUM® Capsules pour le traitement des symptômes de
                      diarrhée
                    </strong>
                  </em>
                </p>
                <ul>
                  <li>Agit rapidement et efficacement</li>
                  <li>Disponible depuis plus de 30 ans déjà</li>
                  {/*<li>Capsules faciles à avaler</li>*/}
                </ul>
                <p className="text-right">
                  <a href="/fr/medicaments-imodium/imodium-capsules/">
                    Plus d'informations sur&nbsp;IMODIUM® Capsules
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
